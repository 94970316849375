<template>
<div>
    <div class="card card-warning card-outline">
        <div class="card-header">
            <div class="card-title">
                <h4><i class="fa fa-briefcase"></i> Zutaten</h4>
                <p>Verfügbare Lebensmittel</p>
            </div>

            <div class="card-tools">
                <button type="button" class="mr-1 btn btn-default btn-sm" @click="$parent.loadProduct">
                    <i class="fas fa-fw fa-sync"></i>
                </button>
                <b-button size="sm" @click="syncIngredients" class="mr-1" variant="danger"><i class="fas fa-fw fa-save" v-if="$auth.check('products.edit')"></i> Lebensmittel speichern</b-button>
                <b-button size="sm" @click="createModal('food')" variant="primary"><i class="fas fa-fw fa-plus-circle" v-if="$auth.check('products.edit')"></i> Lebensmittel hinzufügen</b-button>
            </div>
        </div>

        <div class="card-body">
            <template v-if="product.ingredients.length > 0">
                <div class="table-responsive">
                <table class="table table-hover table-sm">
                    <thead>
                        <!-- <th><input type="checkbox" v-model="selectAll" /></th> -->
                        <th>
                            Name
                        </th>
                        <th>
                            Menge
                        </th>
                        <th>
                            Einheit
                        </th>
                        <th>&Oslash;-EK (Stück)</th>
                        <th>&Oslash;-EK (Gesamt)</th>
                        <th style="width: 140px;">Aktionen</th>                 
                    </thead>
                    <tbody>
                        <tr v-for="(ingredient,index) in product.ingredients" :key="ingredient.id">
                            <!-- <td><input type="checkbox" :value="product.id" v-model="selectedProducts"/></td> -->
                            <td>{{ ingredient.name }}</td>
                            <td>
                                <input type="number" class="form-control form-control-sm" v-model="product.ingredients[index].quantity" />
                            </td>
                            <td>{{ ingredient.article_unit.description }}</td>
                            <td>{{ ingredient.price | toCurrency }}</td>
                            <td>{{ ingredient.price * ingredient.quantity | toCurrency }}</td>
                            <td>
                                <!-- <router-link class="mr-1 btn btn-success btn-xs" :to="{name: 'products.show', params: {id: product.id}}" v-if="$auth.check('products.show') && !$auth.check('products.edit')"><i class="fas fa-eye"></i></router-link>
                                <router-link class="mr-1 btn btn-warning btn-xs" :to="{name: 'products.details', params: {id: product.id}}" v-if="$auth.check('products.edit')"><i class="fas fa-edit"></i></router-link> -->
                                <b-button size="xs" @click="removeIngredient('food', index)" variant="danger" v-if="$auth.check('products.destroy')"><i class="fas fa-trash"></i></b-button> 
                            </td>
                        </tr>
                    </tbody>
                </table>
                </div>
            </template>

            <template v-else>
                <h5>Keine Produkte gefunden</h5>
            </template>
        </div>

        <div class="card-footer">
            <template v-if="product.ingredients.length > 0">
                <span class="float-right"><strong>Kosten Einkauf: {{ totalCostsIngredients | toCurrency }}</strong></span>
            </template>
        </div>
    </div>


    <div class="card card-danger card-outline">
        <div class="card-header">
            <div class="card-title">
                <h4><i class="fa fa-briefcase"></i> Verpackung</h4>
                <p>Verfügbare Verpackungsmaterialien</p>
            </div>

            <div class="card-tools">
                <button type="button" class="mr-1 btn btn-default btn-sm" @click="$parent.loadProduct">
                    <i class="fas fa-fw fa-sync"></i>
                </button>
                <b-button size="sm" @click="syncIngredients" class="mr-1" variant="danger"><i class="fas fa-fw fa-save" v-if="$auth.check('products.edit')"></i> Verpackung speichern</b-button>
                <b-button size="sm" @click="createModal('package')" variant="primary"><i class="fas fa-fw fa-plus-circle" v-if="$auth.check('products.edit')"></i> Verpackung hinzufügen</b-button>
            </div>
        </div>

        <div class="card-body">
            <template v-if="product.packages.length > 0">
                <div class="table-responsive">
                <table class="table table-hover table-sm">
                    <thead>
                        <!-- <th><input type="checkbox" v-model="selectAll" /></th> -->
                        <th>
                            Name
                        </th>
                        <th>
                            Menge
                        </th>
                        <th>
                            Einheit
                        </th>
                        <th>&Oslash;-EK (Stück)</th>
                        <th>&Oslash;-EK (Gesamt)</th>
                        <th style="width: 140px;">Aktionen</th>                 
                    </thead>
                    <tbody>
                        <tr v-for="(ingredient,index) in product.packages" :key="ingredient.id">
                            <!-- <td><input type="checkbox" :value="product.id" v-model="selectedProducts"/></td> -->
                            <td>{{ ingredient.name }}</td>
                            <td>
                                <input type="number" class="form-control form-control-sm" v-model="product.packages[index].quantity" />
                            </td>
                            <td>{{ ingredient.article_unit.description }}</td>
                            <td>{{ ingredient.price | toCurrency }}</td>
                            <td>{{ ingredient.price * ingredient.quantity | toCurrency }}</td>
                            <td>
                                <!-- <router-link class="mr-1 btn btn-success btn-xs" :to="{name: 'products.show', params: {id: product.id}}" v-if="$auth.check('products.show') && !$auth.check('products.edit')"><i class="fas fa-eye"></i></router-link>
                                <router-link class="mr-1 btn btn-warning btn-xs" :to="{name: 'products.details', params: {id: product.id}}" v-if="$auth.check('products.edit')"><i class="fas fa-edit"></i></router-link> -->
                                <b-button size="xs" @click="removeIngredient('package', index)" variant="danger" v-if="$auth.check('products.destroy')"><i class="fas fa-trash"></i></b-button> 
                            </td>
                        </tr>
                    </tbody>
                </table>
                </div>
            </template>

            <template v-else>
                <h5>Keine Lebensmittel gefunden</h5>
            </template>
        </div>

        <div class="card-footer">
            <template v-if="product.packages.length > 0">
                <span class="float-right"><strong>Kosten Einkauf: {{ totalCostsPackages | toCurrency }}</strong></span>
            </template>
        </div>
    </div>

    <b-modal id="ingredientModal" title="Zutaten hinzufügen" ok-only ok-variant="primary" ok-title="Hinzufügen" size="xl" @ok="addIngredients">
        <div class="card card-success card-outline">
            <div class="card-header">
                <div class="card-title">
                    <h4><i class="fa fa-briefcase"></i> Waren</h4>
                    <p>Verfügbare Produkte</p>
                </div>

                <div class="card-tools">
                    <div class="input-group input-group-sm" style="margin-top: 0;">
                        <input type="text" class="form-control form-control-sm" placeholder="Suchen" @keyup.enter="loadIngredients" v-model="search" />
                        <button type="submit" class="btn btn-default btn-sm" @click.prevent="loadIngredients">
                            <i class="fas fa-search"></i>
                        </button>
                        <button v-if="this.search != ''" type="button" class="ml-1 btn btn-danger btn-sm" @click="resetSearch">
                            <i class="fas fa-times"></i>
                        </button>
                    </div>
                </div>
            </div>

            <div class="card-body">
                <template v-if="ingredients.data.length > 0">
                    <div class="table-responsive">
                        <table class="table table-hover table-sm">
                            <thead>
                                <th><input type="checkbox" v-model="selectAll" /></th>
                                <th>
                                    <a href="#" @click.prevent="changeSort('name')">Name</a>
                                    <span v-if="this.params.sort_field == 'name' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                    <span v-if="this.params.sort_field == 'name' && this.params.sort_direction == 'desc'" >&darr;</span>
                                </th>
                                <th>
                                    Preis
                                </th>
                                <th>
                                    Einheit
                                </th>               
                            </thead>
                            <tbody>
                                <tr v-for="(ingredient) in ingredients.data" :key="ingredient.id">
                                    <td><input type="checkbox" :value="ingredient.id" v-model="selectedIngredients"/></td>
                                    <td>{{ ingredient.name }}</td>
                                    <td>{{ ingredient.price | toCurrency}}</td>
                                    <td>{{ ingredient.article_unit.description }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </template>

                <template v-else>
                    <h5>Keine Verpackungen gefunden</h5>
                </template>
            </div>

            <div class="card-footer">
                <template v-if="ingredients.data.length > 0">
                    <div class="row align-items-center">
                        <div class="col-md-9">
                            <pagination class="float-left" :data="ingredients" @pagination-change-page="loadIngredients" :limit="3"></pagination>
                        </div>
                        <div class="col-md-2">
                            <span class="float-right">Anzeige Eintrag {{ ingredients.meta.from }} - {{ ingredients.meta.to }} von {{ ingredients.meta.total }}</span>
                        </div>
                        <div class="col-md-1">
                            <select class="float-right form-control form-control-sm" v-model="params.per_page">
                                <option value="25">25 Artikel</option>
                                <option value="50">50 Artikel</option>
                                <option value="75">75 Artikel</option>
                                <option value="100">100 Artikel</option>
                                <option value="125">125 Artikel</option>
                                <option value="150">150 Artikel</option>
                            </select>
                        </div>
                    </div>                        
                </template>
            </div>
        </div>        
    </b-modal>


</div>
</template>

<script>
export default {
    name: "Recipe",

    props: [
        'product',
    ],

    data(){
        return {
            totalCostsIngredients: 0,
            totalCostsPackages: 0,
            selectedIngredients: [],
            ingredients: {
                data: [],
            },
            params: {
                sort_field: 'name',
                sort_direction: 'asc',
                per_page: 25,
                
            },
            type: 'package',
            search: '',
            page: 1,
        }
    },

    watch: {
        'product.ingredients': {
            handler () {
                this.calculateCosts();
            },
            deep: true
        },
        'product.packages': {
            handler () {
                this.calculateCosts();
            },
            deep: true
        },
        params: {
            handler () {
                this.loadIngredients();
            },
            deep: true
        },
    },

    computed: {
        selectAll: {
            get: function () {
                return this.ingredients.data ? this.selectedIngredients.length == this.ingredients.data.length : false;
            },
            set: function (value) {
                var selected = [];

                if(value) {
                    this.ingredients.data.forEach(function (article) {
                        selected.push(article.id);
                    });
                }

                this.selectedIngredients = selected;
            }
        }
    },

    methods:{
        resetSearch(){
            this.search = '';
            this.getIngredients();
        },

        addIngredients(){
            this.selectedIngredients.forEach(function (item){
                const ingredient = this.ingredients.data.find(element => element.id == item);
                const data = {
                    id: ingredient.id,
                    name: ingredient.name,
                    quantity: 0,
                    price: ingredient.price,
                    article_unit: {
                        description: ingredient.article_unit.description,
                    }
                    // unit: this.units[0].id,
                };

                var alreadyAssigned = false;

                if(this.type == 'food'){

                    if(this.product.ingredients.length == 0)
                    {
                        this.product.ingredients.push(data);
                    }

                    if(this.product.ingredients.length >= 1)
                    {

                        this.product.ingredients.forEach(function (index){
                            if(index.id == data.id){
                                alreadyAssigned = true;
                                return;
                            }
                        }, this);

                        if(alreadyAssigned == false)
                        {
                            this.product.ingredients.push(data);
                        }
                    }
                }

                if(this.type == 'package'){

                    if(this.product.packages.length == 0)
                    {
                        this.product.packages.push(data);
                    }

                    if(this.product.packages.length >= 1)
                    {

                        this.product.packages.forEach(function (index){
                            if(index.id == data.id){
                                alreadyAssigned = true;
                                return;
                            }
                        }, this);

                        if(alreadyAssigned == false)
                        {
                            this.product.packages.push(data);
                        }
                    }
                }


            },this);
            this.selectedIngredients = [];
            this.$bvModal.hide("ingredientModal");            
        },        

        changeSort(field) {
            if(this.params.sort_field === field) {
                this.params.sort_direction = this.params.sort_direction == 'asc' ? 'desc' : 'asc';
    
            }
            else{
                this.params.sort_field = field;
                this.params.sort_direction = 'asc';
            }
        },

        loadIngredients(page){
            if(page == undefined)
            {
                page = this.page 
            }
            this.axios
                .get("/ingredients",{
                    params: {
                        page: page,
                        search: this.search,
                        ...this.params,
                        type: this.type,
                    }
                })
                .then((response) => {
                    this.ingredients = response.data;
                    this.page = page;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        createModal(type) {
            this.type = type;
            this.loadIngredients();
            this.$bvModal.show("ingredientModal");
        },

        calculateCosts(){
            this.totalCostsIngredients = 0;
            this.totalCostsPackages = 0;

            this.product.ingredients.forEach(function(item){
                this.totalCostsIngredients += item.quantity * item.price;
            },this)

            this.product.packages.forEach(function(item){
                this.totalCostsPackages += item.quantity * item.price;
            },this)
        },

        syncIngredients(){
            this.$swal({
                title: "Möchtest du die Änderungen speichern?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `Ja, speichern!!!`,
                denyButtonText: `Abbrechen`,
            })
            .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    this.axios
                    .post('/products/' + this.product.id + '/syncIngredients', {ingredients: this.product.ingredients.concat(this.product.packages)})
                    .then(() => {
                        this.$parent.loadProduct();
                        this.$swal({
                            icon: "success",
                            title: "Änderungen gespeichert!",
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 2000,
                            timerProgressBar: true,
                        });
                    })
                    .catch(() => {
                        this.$swal({
                            icon: "error",
                            title: "Oops...",
                            text: "Da ist etwas schief gelaufen",
                        });
                    });
                }
            });
        },

        removeIngredient(mode, index){
            this.$swal({
                title: "Möchtest du die Zutat wirklich von diesem Produkt löschen?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `Ja, löschen!!!`,
                denyButtonText: `Abbrechen`,
            })
            .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    if(mode == 'food'){
                        this.$delete(this.product.ingredients,index)
                    }
                    if(mode == 'package'){
                        this.$delete(this.product.packages,index)
                    }
                }
            });
        },
    },

    created(){
        this.calculateCosts();
    }
}
</script>

<style>

</style>